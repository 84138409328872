const defaultApplicationsData = [
    {
        "id": 1,
        "name": "TwitterPg"
    },
    {
        "id": 2,
        "name": "TpchPg"
    },
    {
        "id": 3,
        "name": "TwitterMySQL"
    }
];

export default defaultApplicationsData;
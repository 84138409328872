const databaseOptions = [
    {
      value: 'postgresql',
      label: 'PostgreSQL',
    },
    {
      value: 'mysql',
      label: 'MySQL',
    },
];

export default databaseOptions;